import "./App.css";
import Header from "./containers/header/Header.tsx";
import React, { useRef } from "react";
import MainContent from "./containers/main-content/MainContent.tsx";
import SubSection from "./containers/sub-section/SubSection.tsx";
import SectionThree from "./containers/third-section/SectionThree.tsx";
import Footer from "./containers/footer/Footer.tsx";

function App() {
  const downloadRef = useRef<HTMLDivElement>(null);
  const contactRef = useRef<HTMLDivElement>(null);

  const scrollToDownload = () => {
    downloadRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContact = () => {
    contactRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onDiscordClick = () => {
    const discordInviteLink = "https://discord.gg/2HPx5V2ktq";
    window.open(discordInviteLink, "_blank", "noopener,noreferrer");
  };

  const onInstagramClick = () => {
    window.open("https://www.instagram.com/algobytesapp/", "_blank", "noopener,noreferrer");
  };

  const onLinkedinClick = () => {
    window.open("https://www.linkedin.com/company/algobytesapp", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="App">
      <header className="App-header">
        <Header downloadScroll={scrollToDownload} contactScroll={scrollToContact} />
      </header>
      <div ref={downloadRef}>
        <MainContent onDiscordClick={onDiscordClick} contactScroll={scrollToContact} />
      </div>
      <SubSection
        headerText="Byte-Sized Learning"
        description="Sharpen your Data Structures and Algorithms skills in just 5 minutes a day. Our lessons fit seamlessly into
         your busy schedules, helping you build consistency and confidence without feeling overwhelmed!"
        mediaLink="/QuizGif.gif"
        mediaType="IMAGE"
      />
      <SubSection
        headerText="Daily Streaks"
        description="Daily streaks and notifications to keep you on track!  AlgoBytes will hold you accountable to hit your learning goals!"
        mediaLink="StreaksGif.gif"
        mediaType="IMAGE"
        reverse={true}
      />
      <SubSection
        headerText="Learning Roadmap"
        description="Don't know where to start?  Follow AlgoByte's roadmap starting with the fundamentals and progressing on to harder topics! "
        mediaLink="/RoadmapGif.gif"
        mediaType="IMAGE"
      />

      <div ref={contactRef}>
        <SectionThree
          onDiscordClick={onDiscordClick}
          onInstagramClick={onInstagramClick}
          onLinkedinClick={onLinkedinClick}
        />
      </div>
      <Footer />
    </div>
  );
}

export default App;
