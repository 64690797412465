import React from "react";
import "./SectionThree.css";
import FadeInContainer from "../../components/FadeInContainer/FadeInContainer.tsx";

const SectionThree = ({ onDiscordClick, onInstagramClick, onLinkedinClick }) => {
  return (
    <FadeInContainer>
      <div className="section-container">
        <h1 className="header-text">Interested in joining the AlgoBytes community?</h1>
        <p className="paragraph-text">
          Follow us on Instagram, connect with us on LinkedIn, or join our Discord to be notified of any updates!
        </p>
        <div className="icon-container">
          <img src="/Instagram_logo.png" alt="Instagram" className="button" onClick={() => onInstagramClick()} />
          <img src="/LinkedIn_icon.png" alt="LinkedIn" className="button" onClick={() => onLinkedinClick()} />
          <img src="/DiscordSymbol.png" alt="Discord" className="button" onClick={() => onDiscordClick()} />
        </div>
      </div>
    </FadeInContainer>
  );
};

export default SectionThree;
