import React, { useEffect, useRef, useState } from "react";
import "./Header.css";

const Header = ({ downloadScroll, contactScroll }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen((current) => !current);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="container">
      <div className="logo-container" onClick={downloadScroll}>
        <img src="/AlgoBytesLogoTransparent.png" alt="AlgoBytesLogo" className="logo-image" />
        {/* <p className="logo-text">AlgoBytes</p> */}
      </div>

      <span className="span-links">
        <p className="nav-text" onClick={downloadScroll}>
          Home
        </p>
        <p className="nav-text" onClick={downloadScroll}>
          Download
        </p>
        <p className="nav-text" onClick={contactScroll}>
          Contact
        </p>
      </span>
      <div ref={menuRef} className={`header-link-container ${isOpen ? "open" : ""}`}>
        <p onClick={() => downloadScroll()}> Home </p>
        <p onClick={() => downloadScroll()}> Download </p>
        <p onClick={() => contactScroll()}> Contact </p>
      </div>
      <div className="nav-toggle" onClick={toggleOpen}>
        <p>&#9776;</p>
      </div>
    </div>
  );
};

export default Header;
