import React, { useEffect } from "react";
import Header from "../header/Header.tsx";
import Footer from "../footer/Footer.tsx";
import "../../App.css";
import "./PasswordResetChange.css";
import supabase from "../../supabaseClient.js";
import { useNavigate } from "react-router-dom";

type PasswordCriteria = {
  length: boolean;
  symbols: boolean;
  numbers: boolean;
  upperCase: boolean;
  noWhitespace: boolean;
  match: boolean;
};

const PasswordResetUpdateForm = () => {
  const [password, setPassword] = React.useState<string>("");
  const [confirmPassword, setConfirmPassword] = React.useState<string>("");
  const [passwordResetError, setPasswordResetError] = React.useState<any>(null);
  const [showUpdateForm, setShowUpdateForm] = React.useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = React.useState<boolean>(false);
  const [passwordCriteria, setPasswordCriteria] = React.useState<PasswordCriteria>({
    length: false,
    numbers: false,
    symbols: false,
    upperCase: false,
    noWhitespace: false,
    match: false,
  });
  const [canUpdate, setCanUpdate] = React.useState<boolean>(false);

  const nav = useNavigate();

  useEffect(() => {
    const fullUrl: string = window.location.href;
    const firstHashIndex: number = fullUrl.indexOf("#");
    const secondHashIndex: number = fullUrl.indexOf("#", firstHashIndex + 1);

    let params: URLSearchParams = new URLSearchParams(fullUrl.substring(secondHashIndex + 1));

    const error = params.get("error");
    const errorCode = params.get("error_code");
    const errorDescription = params.get("error_description");

    if (error) {
      setPasswordResetError({ error, errorCode, errorDescription });
    } else if (secondHashIndex === -1) {
      setPasswordResetError({
        ...passwordResetError,
        error: "The password reset link is invalid",
        errorDescription: "Please request a new reset email and try again",
      });
    }
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "INITIAL_SESSION") {
        //Remove first # from URL and replace subsequent ones.
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash.replace("#", "&"));
        const accessToken = params.get("access_token");
        const refreshToken = params.get("refresh_token");
        if (accessToken && refreshToken) {
          const { data, error } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken,
          });
          if (error) {
          } else {
            setShowUpdateForm(true);
          }
        }
      } else if (event === "PASSWORD_RECOVERY") {
        setShowUpdateForm(true);
      }
    });
  }, []);

  const updatePassword = async () => {
    const { data, error } = await supabase.auth.updateUser({
      password: password,
    });
    if (data && !error) {
      setUpdateSuccess(true);
      setShowUpdateForm(false);
    }
    if (error) {
      console.error(error);
      setPasswordResetError({
        ...passwordResetError,
        error: "An error ocurred when updating your password",
        errorDescription: error.message,
      });
      setShowUpdateForm(false);
    }
  };

  useEffect(() => {
    let tempPass = password ? password : "";
    let tempConfirm = confirmPassword ? confirmPassword : "";
    const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const whitespaceRegex = /\s/;

    setPasswordCriteria({
      length: tempPass.length >= 8,
      numbers: numberRegex.test(tempPass),
      symbols: symbolRegex.test(tempPass),
      upperCase: uppercaseRegex.test(tempPass) && lowercaseRegex.test(tempPass),
      noWhitespace: !whitespaceRegex.test(tempPass),
      match: tempPass === tempConfirm && tempPass !== "" && confirmPassword !== "",
    });
  }, [password, confirmPassword]);

  useEffect(() => {
    setCanUpdate(
      passwordCriteria.length &&
        passwordCriteria.noWhitespace &&
        passwordCriteria.symbols &&
        passwordCriteria.numbers &&
        passwordCriteria.match &&
        passwordCriteria.upperCase
    );
  }, [passwordCriteria]);

  return (
    <div className="parent-container">
      <header className="header-container">
        <Header downloadScroll={() => nav("/")} contactScroll={() => nav("/")} />
      </header>
      {!passwordResetError && !showUpdateForm && !updateSuccess && (
        <div className="secondary-container">
          <div className="form-header">Sorry! This password reset link is invalid</div>
          <div className="subtext">Please request a new reset email and try again!</div>
        </div>
      )}
      {passwordResetError && (
        <div className="secondary-container">
          <div className="form-header">{passwordResetError.error}</div>
          <div className="subtext">{passwordResetError.errorDescription}</div>
        </div>
      )}
      {showUpdateForm && (
        <div className="form-container">
          <div className="form-header">Please enter a new password!</div>
          <div className="password-container">
            <div className="input-container">
              <label>New Password</label>
              <input
                className="input"
                name="password"
                type="password"
                placeholder="yourPassword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="password-criteria-container">
                <div className={passwordCriteria.length ? "password-criteria-valid" : "password-criteria-invalid"}>
                  {passwordCriteria.length ? "✔ " : "✖ "}Password must contain atleast 8 characters
                </div>
                <div className={passwordCriteria.numbers ? "password-criteria-valid" : "password-criteria-invalid"}>
                  {passwordCriteria.numbers ? "✔ " : "✖ "}Password must contain numbers
                </div>
                <div className={passwordCriteria.symbols ? "password-criteria-valid" : "password-criteria-invalid"}>
                  {passwordCriteria.symbols ? "✔ " : "✖ "}Password must contain symbols ($#!...)
                </div>
                <div className={passwordCriteria.upperCase ? "password-criteria-valid" : "password-criteria-invalid"}>
                  {passwordCriteria.upperCase ? "✔ " : "✖ "}Password must contain upper and lower case letters
                </div>
                {!passwordCriteria.noWhitespace && (
                  <div className="password-criteria-invalid">✖ Password cannot contain whitespace</div>
                )}
              </div>
            </div>
            <div className="input-container">
              <label>Confirm Password</label>
              <input
                className="input"
                name="confirmPassword"
                type="password"
                placeholder="confirm Your Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="password-criteria-container"></div>
              {confirmPassword && (
                <div className={passwordCriteria.match ? "password-criteria-valid" : "password-criteria-invalid"}>
                  {passwordCriteria.match ? "✔ " : "✖ "}Passwords must match
                </div>
              )}
            </div>
          </div>
          <div className="button-container">
            <button className="PrimaryButton" disabled={!canUpdate} onClick={updatePassword}>
              Update Password
            </button>
          </div>
        </div>
      )}
      {updateSuccess && (
        <div className="secondary-container">
          <div className="form-header">Password successfully updated!</div>
          <div className="subtext">Please return to the app and login</div>
        </div>
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default PasswordResetUpdateForm;
